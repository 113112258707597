import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { GeoDrawer, GeoDrawerHeader } from "./geo-side-menu";
import {
  Datagrid,
  Link,
  useCreatePath,
  useGetList,
  useGetOne,
} from "react-admin";
import {
  Container,
  Typography,
  Box,
  Paper,
  LinearProgress,
} from "@mui/material";
import { computeCoverageByCustomerId } from "../geo/geo-api";
import { GeoCoverageStats } from "./geo-query-tool-panel";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { toggleCustomerProfile } from "./store";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const PaperWrapper = ({ title, children }) => {
  return (
    <Paper elevation={3} sx={{ p: 2, mt: 1 }}>
      {title && (
        <Typography variant="body1" gutterBottom>
          {title}
        </Typography>
      )}
      {children}
    </Paper>
  );
};

const CustomerCoverage = ({ id }) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        setIsLoading(true);
        const res = await computeCoverageByCustomerId(id);
        setData(res.data);
      } catch (err) {
        setData(null);
      } finally {
        setIsLoading(false);
      }
    };
    if (id) fetchData(id);
  }, [id]);
  if (isLoading) {
    return (
      <PaperWrapper title="Cobertura">
        <LinearProgress color="secondary" />
      </PaperWrapper>
    );
  }

  return (
    <PaperWrapper>
      <Typography variant="body1" gutterBottom>
        <b>COBERTURA</b>
      </Typography>
      {data ? (
        <GeoCoverageStats stats={data} />
      ) : (
        <Typography>Erro ao obter informações de cobertura!</Typography>
      )}
    </PaperWrapper>
  );
};

const CustomerBasic = ({ loading, customer }) => {
  const createPath = useCreatePath();
  if (loading) {
    return (
      <PaperWrapper title="Carregando informações da radio...">
        <LinearProgress color="secondary" />
      </PaperWrapper>
    );
  }
  if (customer) {
    return (
      <Paper elevation={3} sx={{ p: 2, mt: 0 }}>
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr", gap: 1 }}>
          <Typography>
            <Link
              to={createPath({
                resource: "customer",
                type: "show",
                id: customer.id,
              })}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ display: "flex" }}
            >
              <b>{customer.profile.name}</b>
              <OpenInNewIcon />
            </Link>
          </Typography>
          <Typography>
            <a href={`mailto:${customer.email}`}>{customer.email}</a>
          </Typography>
          <Typography>{customer.active ? "Ativo" : "Inativo"}</Typography>

          <Typography>Plano: {customer.profile.type_plan.name}</Typography>
          <Typography>Região: {customer.profile.region.name}</Typography>
          <Typography>
            Número de Registro: {customer.profile.register_number}
          </Typography>
          <Typography>Cidade: {customer.address.city.name}</Typography>
          <Typography>Estado: {customer.address.city.state.name}</Typography>
          <Typography>CEP: {customer.address.zip_code}</Typography>
          <Typography>Rua: {customer.address.street}</Typography>
          <Typography>Número: {customer.address.number}</Typography>
          <Typography>
            Complemento: {customer.address.complement || "Nenhum"}
          </Typography>
          <Typography>Bairro: {customer.address.neighborhood}</Typography>
        </Box>
      </Paper>
    );
  }
  return (
    <PaperWrapper>
      <Typography variant="body1">Sem informações para exibir</Typography>
    </PaperWrapper>
  );
};

const CustomerTransmitters = ({ loading, transmitters }) => {
  const createPath = useCreatePath();
  if (loading) {
    return (
      <PaperWrapper title="Carregando informações dos transmissores...">
        <LinearProgress color="secondary" />
      </PaperWrapper>
    );
  }
  if (transmitters) {
    return (
      <PaperWrapper>
        <Typography variant="body1" gutterBottom>
          <b>TRANSMISSORES</b>
        </Typography>
        <List dense>
          {transmitters.map((t) => {
            return (
              <ListItemButton key={t.id} divider>
                <ListItemText>
                  <Typography variant="subtitle1">{t.name}</Typography>
                  <Typography>Tipo: {t.type}</Typography>
                  <Typography>Alcance: {t.radius} m</Typography>
                </ListItemText>
                <ListItemSecondaryAction>
                  <Link
                    to={createPath({
                      resource: "customer_transmitter",
                      type: "edit",
                      id: t.id,
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ display: "flex" }}
                  >
                    <OpenInNewIcon />
                  </Link>
                </ListItemSecondaryAction>
              </ListItemButton>
            );
          })}
        </List>
      </PaperWrapper>
    );
  }
  return (
    <PaperWrapper>
      <Typography variant="body1">Nenhum transmissor encontrado</Typography>
    </PaperWrapper>
  );
};

const CustomerStaff = ({ loading, staff }) => {
  if (loading) {
    return (
      <PaperWrapper title="Carregando informações dos staff...">
        <LinearProgress color="secondary" />
      </PaperWrapper>
    );
  }
  if (staff) {
    return (
      <PaperWrapper>
        <Typography variant="body1" gutterBottom>
          <b>QUEM FAZ A RADIO</b>
        </Typography>
        <List dense>
          {staff.map((t) => {
            return (
              <ListItemButton key={t.id} divider>
                <ListItemText>
                  <Typography variant="body2">{t.position}</Typography>
                  <Typography>{t.name}</Typography>
                  <Typography>{t.phone}</Typography>
                  <Typography>{t.email}</Typography>
                </ListItemText>
              </ListItemButton>
            );
          })}
        </List>
      </PaperWrapper>
    );
  }
  return (
    <PaperWrapper>
      <Typography variant="body1">Nenhum membro do staff encontrado</Typography>
    </PaperWrapper>
  );
};

const GeoCustomerProfile = () => {
  const dispatch = useDispatch();
  const { showCustomerProfile, customerId } = useSelector(
    (state) => ({
      showCustomerProfile: state.showCustomerProfile,
      customerId: state.customerId,
    }),
    shallowEqual
  );

  const { data: customerData, isLoading: isLoadingCustomerData } = useGetOne(
    "customer",
    { id: customerId }
  );

  const { data: staffData, isLoading: isLoadingStaffData } = useGetList(
    "customer_staff_member",
    {
      pagination: { page: 1, perPage: 1000000 },
      sort: { field: "id", order: "ASC" },
      filter: { customer_id: customerId },
    }
  );

  const { data: transmitterData, isLoading: isLoadingTransmitterData } =
    useGetList("customer_transmitter", {
      pagination: { page: 1, perPage: 1000000 },
      sort: { field: "id", order: "ASC" },
      filter: { customer_id: customerId },
    });

  return (
    <GeoDrawer open={showCustomerProfile}>
      <GeoDrawerHeader>
        <Box textAlign="right" sx={{ color: "white" }}>
          <IconButton
            onClick={() => dispatch(toggleCustomerProfile(false))}
            color="inherit"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="h6">Perfil da radio</Typography>

        <CustomerBasic
          customer={customerData}
          loading={isLoadingCustomerData}
        />
        <CustomerCoverage id={customerId} />
        <CustomerTransmitters
          transmitters={transmitterData}
          loading={isLoadingTransmitterData}
        />
        <CustomerStaff staff={staffData} loading={isLoadingStaffData} />
      </GeoDrawerHeader>
    </GeoDrawer>
  );
};

const GeoCustomerProfileWrapper = () => {
  const show = useSelector((state) => state.showCustomerProfile);
  if (show) return <GeoCustomerProfile />;
  return null;
};

export default GeoCustomerProfileWrapper;
