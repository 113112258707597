import React from "react";
import { TopToolbar, CreateButton } from "react-admin";

const CustomListCreateToolbar = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

export default CustomListCreateToolbar;
