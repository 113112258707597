import { useState } from "react";
import {
  ListBase,
  Title,
  Datagrid,
  useRecordContext,
  useGetList,
} from "react-admin";
import { Table, TableHead, TableBody } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../../ui/table-layout";
import { DatagridSX, ListSX } from "../../../styled/custom";
import ProgrammingMapListTitle from "../../../ui/programming-map-list-title";
import ButtonDownloadField from "../../../ui/button-download-field";
import ControlAudio from "../../../ui/control-audio";

// record columns
const firstRecordColumn = {
  backgroundColor: "white",
  color: "black",
  style: { width: "150px", minWidth: "150px" },
};

const secondRecordColumn = {
  backgroundColor: "white",
  color: "black",
  style: { width: "100px", minWidth: "100px" },
};

const lastRecordColumn = {
  backgroundColor: "white",
  color: "black",
  style: { width: "100px", maxWidth: "100px" },
};

// spots columns
const firstSpotColumn = {
  style: { textAlign: "center", width: "250px", maxWidth: "250px" },
  backgroundColor: "#9e9e9e14",
  color: "black",
  colSpan: 2,
};

const tableSpotColumn = {
  backgroundColor: "black",
};

const CellHeader = ({ value }) => {
  return (
    <StyledTableCell
      backgroundColor="black"
      style={{ minWidth: "30px", width: "30px" }}
    >
      {value}
    </StyledTableCell>
  );
};

const CellBody = ({ value }) => {
  const valueToNumber = Number(value);
  return (
    <StyledTableCell
      color={valueToNumber === 0 ? "white" : "black"}
      backgroundColor={valueToNumber === 0 ? "#FEFEFE" : "#CCC"}
      border="1px solid black"
      style={{
        minWidth: "30px",
        width: "30px",
        fontWeight: "500",
      }}
    >
      {valueToNumber}
    </StyledTableCell>
  );
};

const ContainerMultimedia = ({ children }) => {
  return (
    <div style={{ margin: "5px auto", width: "fit-content" }}>{children}</div>
  );
};

const TableProgrammingMap = ({ days }) => {
  return (
    <Table>
      <TableHead>
        <StyledTableRow>
          <StyledTableCell
            {...firstRecordColumn}
            backgroundColor="black"
            color="white"
          >
            Cliente
          </StyledTableCell>

          <StyledTableCell
            {...secondRecordColumn}
            backgroundColor="black"
            color="white"
          >
            Validade do contrato
          </StyledTableCell>

          <StyledTableCell>
            {/* <StyledTableRow> */}
            <div>
              <h2 style={{ textAlign: "center" }}>Distribuição mensal</h2>
              <Table style={{ height: "30px" }}>
                <TableBody>
                  <StyledTableRow style={{ color: "white" }}>
                    {days.map((item, i) => (
                      <CellHeader key={i} value={item.id} />
                    ))}
                  </StyledTableRow>
                </TableBody>
              </Table>
            </div>
            {/* </StyledTableRow> */}
          </StyledTableCell>

          <StyledTableCell
            {...lastRecordColumn}
            backgroundColor="black"
            color="white"
          >
            Quantidade mensal
          </StyledTableCell>
        </StyledTableRow>
      </TableHead>
    </Table>
  );
};

const BodyByRecord = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);

  return (
    <Table>
      <TableBody>
        <StyledTableRow
          style={{ cursor: "pointer" }}
          onClick={() => setOpen((open) => !open)}
        >
          <StyledTableCell {...firstRecordColumn} backgroundColor="#ededed">
            <strong>{record.name}</strong>
          </StyledTableCell>

          <StyledTableCell {...secondRecordColumn} backgroundColor="#ededed">
            {record.contract_validity_format}
          </StyledTableCell>

          <StyledTableCell>
            <Table style={{ height: "30px" }}>
              <TableBody>
                <StyledTableRow style={{ color: "white" }}>
                  {record.days.map((item, i) => (
                    <CellBody key={i} value={item.number_inserts} />
                  ))}
                </StyledTableRow>
              </TableBody>
            </Table>
          </StyledTableCell>

          <StyledTableCell {...lastRecordColumn}>
            {record.total_inserts}
          </StyledTableCell>
        </StyledTableRow>

        {record.spots.map((row, index) => {
          if (!open) return null;

          return (
            <StyledTableRow key={index}>
              <StyledTableCell {...firstSpotColumn}>{row.name}</StyledTableCell>

              <StyledTableCell {...tableSpotColumn}>
                <Table style={{ height: "30px" }}>
                  <TableHead>
                    <StyledTableRow>
                      {row.days.map((item, i) => (
                        <CellBody key={i} value={item.number_inserts} />
                      ))}
                    </StyledTableRow>
                  </TableHead>
                </Table>

                <Table style={{ border: "1px solid black" }}>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell
                        {...firstRecordColumn}
                        backgroundColor="black"
                        color="white"
                      >
                        Horário
                      </StyledTableCell>
                      <StyledTableCell
                        {...secondRecordColumn}
                        backgroundColor="black"
                        color="white"
                      >
                        Formato
                      </StyledTableCell>
                      <StyledTableCell
                        {...lastRecordColumn}
                        backgroundColor="black"
                        color="white"
                      >
                        Esquema
                      </StyledTableCell>
                      <StyledTableCell
                        {...lastRecordColumn}
                        backgroundColor="black"
                        color="white"
                      >
                        Player
                      </StyledTableCell>
                      <StyledTableCell
                        {...lastRecordColumn}
                        backgroundColor="black"
                        color="white"
                      >
                        Download
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell backgroundColor="white" color="black">
                        {row.hour_broadcast}
                      </StyledTableCell>
                      <StyledTableCell backgroundColor="white" color="black">
                        {row.type_spot_name}
                      </StyledTableCell>
                      <StyledTableCell backgroundColor="white" color="black">
                        {row.type_scheme_name}
                      </StyledTableCell>
                      <StyledTableCell backgroundColor="white" color="black">
                        <ContainerMultimedia>
                          <ControlAudio
                            multiple={true}
                            data={row.files}
                            label="Player"
                          />
                        </ContainerMultimedia>
                      </StyledTableCell>
                      <StyledTableCell backgroundColor="white" color="black">
                        <ContainerMultimedia>
                          <ButtonDownloadField source="spot" label="Download" />
                        </ContainerMultimedia>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </StyledTableCell>

              <StyledTableCell {...lastRecordColumn}>
                {row.total_inserts}
              </StyledTableCell>
            </StyledTableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const CustomerProgrammingMapList = (props) => {
  const {
    data: days,
    isLoading,
    error,
  } = useGetList("customer-programming-map/days", {
    pagination: { page: 1, perPage: 10 },
    sort: { field: "published_at", order: "DESC" },
  });

  if (isLoading) return null;
  if (error) return <>{error.toString()}</>;

  return (
    <ListBase {...props} sx={ListSX}>
      <Title title={<ProgrammingMapListTitle />} />
      <Datagrid
        bulkActionButtons={false}
        header={<TableProgrammingMap days={days} />}
        sx={{
          ...DatagridSX,
          "& .RaDatagrid-thead": { display: "none" },
          "& .MuiTableCell-body": { padding: 0 },
        }}
      >
        <BodyByRecord />
      </Datagrid>
    </ListBase>
  );
};

export default CustomerProgrammingMapList;
