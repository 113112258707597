import { useCallback, useState } from "react";
import { useInput } from "react-admin";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Box,
  Chip,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = -48;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomSelect = ({
  data,
  multiple = true,
  optionText = "name",
  ...props
}) => {
  const [values, setValues] = useState([]);
  const [loadInitialValue, setLoadInitialValue] = useState(false);

  const { onChange, onBlur, ...rest } = props;
  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired,
  } = useInput({ onChange, onBlur, ...props });

  if (!loadInitialValue && field.value) {
    setValues(field.value);
    setLoadInitialValue(true);
  }

  const handleChange = useCallback(
    (e) => {
      const {
        target: { value },
      } = e;
      const content = multiple
        ? typeof value === "string"
          ? value.split(",")
          : value
        : value;
      setValues(content);
      field.onChange(content);
    },
    [multiple, field]
  );

  const handleBlur = () => {
    field.onBlur(values);
  };

  const idToName = (selected) => {
    return data.filter((item) =>
      typeof selected === "number"
        ? item.id === selected
        : selected.indexOf(item.id) > -1
    );
  };

  return (
    <FormControl error={(isTouched || isSubmitted) && invalid} {...rest}>
      <InputLabel required={isRequired}>{props.label}</InputLabel>
      <Select
        {...field}
        value={values}
        multiple={multiple}
        onChange={handleChange}
        onBlur={handleBlur}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => {
          if (!multiple) {
            const [item] = idToName(selected);
            return item[optionText.split(".")];
          }

          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {idToName(selected).map((item, index) => (
                <Chip key={index} label={item[optionText.split(".")]} />
              ))}
            </Box>
          );
        }}
        MenuProps={MenuProps}
      >
        {data.map((item, index) => (
          <MenuItem key={index} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {(isTouched || isSubmitted) && invalid && (
        <FormHelperText>{error.message}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomSelect;
