import {
  ReferenceInput,
  SelectInput,
  AutocompleteInput
} from "react-admin";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import CustomTextField from "../ui/custom-text-field";
import QuickFilter from "../ui/quick-filter";

const CustomFilterCustomer = [
  <CustomTextField
    label="Buscar"
    source="q"
    alwaysOn
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput label="Programa" source="program_id" reference="program">
    <SelectInput
      label="Programa"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}
    />
  </ReferenceInput>,
  <ReferenceInput label="Cidade" source="city_id" reference="setup/cities">
    <AutocompleteInput
      label="Cidade"
      optionText="name"
      optionValue="id"
      limitChoicesToValue={true}
      shouldRenderSuggestions={(val) => {
        return val ? val.trim().length > 2 : false;
      }}
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}
    />
  </ReferenceInput>,
  <ReferenceInput label="Região" source="region_id" reference="region">
    <SelectInput
      label="Região"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}
    />
  </ReferenceInput>,
  <ReferenceInput label="Tipo de Plano" source="type_plan_id" reference="type-plan">
    <SelectInput
      label="Tipo de Plano"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}
    />
  </ReferenceInput>,
  <QuickFilter source="activated" label="Ativos" defaultValue={true} />,
  <QuickFilter source="disabled" label="Desativados" defaultValue={true} />
];

export default CustomFilterCustomer;
