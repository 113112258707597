import {
  GoogleMap,
} from "@react-google-maps/api";
import { useDispatch } from "react-redux";
import { setMap } from "./store";


const GeoMaps = ({ children }) => {
  const dispatch = useDispatch();

  return (
    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: "100%",
      }}
      center={{ lat: -19.9109697, lng: -43.9466398 }}
      zoom={12}
      onLoad={map => dispatch(setMap(map))}
    >
      {children}
    </GoogleMap>
  );
};

export default GeoMaps;
