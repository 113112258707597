
export function convertToWKT(coordinates) {
  if (!Array.isArray(coordinates) || coordinates.length < 3) {
    throw new Error("Invalid input. A polygon should have at least 3 points.");
  }

  const wktCoords = coordinates.map(
    (coord) => `${coord.longitude} ${coord.latitude}`
  );
  wktCoords.push(`${coordinates[0].longitude} ${coordinates[0].latitude}`); // Repetir a primeira coordenada para fechar o polígono
  return `POLYGON ((${wktCoords.join(", ")}))`;
}

export function polygonToWKT(polygon) {
  const path = polygon.getPath();
  const coordinates = path
    .getArray()
    .map((latLng) => ({ latitude: latLng.lat(), longitude: latLng.lng() }));
  return convertToWKT(coordinates);
}
