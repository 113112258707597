import { Edit, SimpleForm, BooleanInput, useGetList } from 'react-admin';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import EditTitle from '../../../ui/edit-title';
import CustomEditToolbar from '../../../ui/custom-edit-toolbar';
import CustomTextField from '../../../ui/custom-text-field';
import CustomAutocomplete from '../../../ui/custom-autocomplete';
import { ContentPageSX } from '../../../styled/custom';
import { leastOneOption, minLength } from '../../../utils/schemas';

const Schema = Yup.object(
{
  name: minLength(3, "Digite o nome"),
  cities_id: leastOneOption("Selecione pelo menos uma cidade")
});

const RegionEdit = (props) =>
{
  const { data, isLoading } = useGetList("setup/cities");
  return (
    <Edit
      title={ <EditTitle subtitle="Editar Região: " source="name" /> }
      redirect="list"
      mutationMode="pessimistic"
      sx={ContentPageSX}>
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomEditToolbar /> }>
        <CustomTextField source="id" label="Id" disabled />
        <CustomTextField name="name" label="Nome" fullWidth />
        {!isLoading && <CustomAutocomplete data={data} name="cities_id" label="Cidade(s)" fullWidth isrequired="true"/>}
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  )
}

export default RegionEdit;
