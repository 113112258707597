import React from "react";
import {
  Show,
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField,
  TabbedShowLayout,
  ReferenceManyField,
  Datagrid,
  EmailField,
  Tab,
  List,
  useShowContext,
  BooleanField,
  EditButton,
  DeleteWithConfirmButton,
} from "react-admin";
import {
  ContentPageSX,
  DatagridSX,
  SimpleShowLayoutSX,
} from "../../../styled/custom";
import ButtonCreateRelated from "../../../ui/button-create-related";
import Box from "@mui/material/Box";

const CustomerShow = (props) => {
  const record = useShowContext();
  console.log(record);
  return (
    <Show title="Afiliado" sx={ContentPageSX}>
      <TabbedShowLayout sx={SimpleShowLayoutSX}>
        <Tab label="Geral">
          <TextField source="id" />
          <TextField
            source="profile.type_plan.name"
            label="Plano"
            sortable={false}
          />
          <TextField source="profile.name" label="Nome" sortable={false} />
          <TextField source="email" label="E-mail" sortable={false} />
          <TextField
            source="profile.register_number"
            label="CNPJ"
            sortable={false}
          />
          <TextField
            source="profile.region.name"
            label="Região"
            sortable={false}
          />
          <TextField
            source="address.city.name"
            label="Cidade"
            sortable={false}
          />
          <TextField source="address.zip_code" label="CEP" sortable={false} />
          <TextField source="address.street" label="Rua" sortable={false} />
          <TextField source="address.number" label="Número" sortable={false} />
          <TextField
            source="address.complement"
            label="Complemento"
            sortable={false}
          />
          <TextField
            source="address.neighborhood"
            label="Bairro"
            sortable={false}
          />
          <DateField
            source="created_at"
            locales="pt-BR"
            label="Criado em"
            sortable={false}
            showTime
          />
          <ArrayField label="Programa(s) Retransmitido(s)" source="programs">
            <SingleFieldList linkType={false}>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
        </Tab>

        <Tab label="Transmissores" path="transmitters">
          <ReferenceManyField
            label="Transmissores da Afiliada"
            reference="customer_transmitter"
            target="customer_id"
          >
            <Box textAlign="right" sx={{ paddingY: 1 }}>
              <ButtonCreateRelated
                resource="customer_transmitter"
                field="id"
                relatedField="customer_id"
              />
            </Box>
            <Datagrid bulkActionButtons={false} sx={DatagridSX} rowClick="show">
              <TextField source="id" />
              <TextField
                source="customer.name"
                label="Afiliada"
                sortable={false}
              />
              <TextField source="name" label="Nome" sortable={false} />
              <TextField source="type" label="Tipo" sortable={false} />
              <TextField source="latitude" label="Latitude" sortable={false} />
              <TextField
                source="longitude"
                label="Longitude"
                sortable={false}
              />
              <TextField
                source="radius"
                label="Raio de alcance (metros)"
                sortable={false}
              />
              <BooleanField source="active" label="Ativo" />
              <EditButton />
              <DeleteWithConfirmButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Membros do staff" path="staff">
          <ReferenceManyField
            label="Membros do Staff da Afiliada"
            reference="customer_staff_member"
            target="customer_id"
          >
            <Box textAlign="right" sx={{ paddingY: 1 }}>
              <ButtonCreateRelated
                resource="customer_staff_member"
                field="id"
                relatedField="customer_id"
              />
            </Box>
            <Datagrid bulkActionButtons={false} sx={DatagridSX} rowClick="show">
              <TextField source="name" label="Nome" />
              <TextField source="position" label="Cargo" />
              <TextField source="phone" label="Telefone" />
              <EmailField source="email" label="Email" />
              <EditButton />
              <DeleteWithConfirmButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default CustomerShow;
