import React, { useEffect } from "react";
import { Introduction } from "./geo/introduction";
import Box from "@mui/material/Box";
import GeoAppLayout from "./geo/geo-app-layout";
import { AffiliationRequest } from "./geo/affiliation-request";
import { GeoSideMenu } from "./geo/geo-side-menu";
import GeoMaps from "./geo/geo-maps";
import GeoLayers from "./geo/geo-layers";
import DelayedRender from "../ui/delayed-render";
import { useAuthState, usePermissions } from "react-admin";
import PublicRadiosLayer from "./geo/layers/public-radios";
import GeoTools from "./geo/geo-tools";
import geoApi from "./geo/geo-api";
import { Provider, useDispatch, useSelector } from "react-redux";
import { createStore, getAppStore, setIsAdmin, setPublicRadios } from "./geo/store";
import GeoQueryToolPanel from "./geo/geo-query-tool-panel";
import GeoCustomerProfile from "./geo/geo-customer-profile";

const GeoMapsItems = () => {
  return (
    <DelayedRender delay={100}>
      <GeoLayers key="geo-layers" />
      <GeoTools />
    </DelayedRender>
  );
};

const GeoApp = () => {
  const { authenticated } = useAuthState();
  const { permissions } = usePermissions();
  const isAdmin = useSelector(state => state.isAdmin);
  const dispatch = useDispatch();


  useEffect(() => {
    const condition = authenticated && (permissions && permissions !== "admin-customer");
    dispatch(setIsAdmin(condition));
  }, [authenticated, permissions]);

  useEffect(() => {
    const fetchRadios = async () => {
      try {
        const result = await geoApi.get("/geo/public/radios");
        dispatch(setPublicRadios(result.data.result));
      } catch (err) {
        console.log(err);
      }
    };
    fetchRadios();
  }, []);

  return (
    <>
      <GeoAppLayout>
        <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
          <GeoSideMenu />
          {isAdmin && <GeoQueryToolPanel />}
          {isAdmin && <GeoCustomerProfile />}
          <Box sx={{ flexGrow: 1 }}>
            <GeoMaps>
              {isAdmin && <GeoMapsItems />}
              {!isAdmin && <PublicRadiosLayer />}
            </GeoMaps>
          </Box>
        </Box>
      </GeoAppLayout>
      <Introduction />
      <AffiliationRequest />
    </>
  );
};



export const GeoMainPage = (props) => {
  return (
    <Provider store={getAppStore()}>
      <GeoApp />
    </Provider>
  );
};

export default GeoMainPage;
