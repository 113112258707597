import React from 'react';
import { List, Datagrid, TextField, DateField, EditButton, BooleanField } from 'react-admin';
import { DatagridSX, ListSX } from '../../../styled/custom';
import CustomFilterCustomer from '../../../filter/custom-filter-customer';
import CustomListDefaultToolbar from '../../../ui/custom-list-default-toolbar';
import CustomerExporter from '../../../exporter/customer-exporter';

const CustomerList = (props) => (
  <List
    title="Afiliados"
    sort={{ field: 'id', order: 'desc' }}
    filters={ CustomFilterCustomer }
    exporter={ CustomerExporter }
    actions={ <CustomListDefaultToolbar /> }
    sx={ListSX}>
    <Datagrid
      rowClick="show"
      bulkActionButtons={false}
      sx={DatagridSX}>
      <TextField source="id" sortable={false} />
      <TextField source="profile.type_plan.name" label="Plano" sortable={false} />
      <TextField source="profile.name" label="Nome" sortable={false} />
      <TextField source="email" label="E-mail" sortable={false} />
      <TextField source="profile.register_number" label="CNPJ" sortable={false} />
      <BooleanField source="active" label="Ativo" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
      <EditButton />
    </Datagrid>
  </List>
);

export default CustomerList;
