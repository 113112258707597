import React, { useEffect } from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MapIcon from "@mui/icons-material/Map";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import SwitchRed from "../../styled/switch-red";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowAffiliatesLayer,
  setShowCoverageLayer,
  setShowPopulationLayer,
  toggleQueryTool,
} from "./store";

const MapViews = () => {
  const {
    showAffiliatesLayer,
    showCoverageLayer,
    showPopulationLayer,
    showQueryTool,
  } = useSelector((state) => ({
    showAffiliatesLayer: state.showAffiliatesLayer,
    showCoverageLayer: state.showCoverageLayer,
    showPopulationLayer: state.showPopulationLayer,
    showQueryTool: state.showQueryTool,
  }));
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "layers" : undefined;

  return (
    <>
      <Button
        startIcon={<MapIcon />}
        endIcon={<ExpandMoreIcon />}
        onClick={handleClick}
      >
        Visualizar por
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <List dense>
          <ListItem>
            <ListItemIcon>
              <SwitchRed
                checked={showPopulationLayer}
                onChange={(e, checked) =>
                  dispatch(setShowPopulationLayer(checked))
                }
              />
            </ListItemIcon>
            <ListItemText>Habitantes (Mapa de Calor)</ListItemText>
            <ListItemSecondaryAction />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SwitchRed
                checked={showAffiliatesLayer}
                onChange={(e, checked) =>
                  dispatch(setShowAffiliatesLayer(checked))
                }
              />
            </ListItemIcon>
            <ListItemText>Afiliadas</ListItemText>
            <ListItemSecondaryAction />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SwitchRed
                checked={showCoverageLayer}
                onChange={(e, checked) => dispatch(setShowCoverageLayer(checked))}
              />
            </ListItemIcon>
            <ListItemText>Alcance</ListItemText>
            <ListItemSecondaryAction />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SwitchRed
                checked={showQueryTool}
                onChange={(e, checked) => dispatch(toggleQueryTool(checked))}
              />
            </ListItemIcon>
            <ListItemText>Ferramenta de alcance</ListItemText>
            <ListItemSecondaryAction />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default React.memo(MapViews);
