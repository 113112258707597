import React from "react";
import { BooleanInput, Edit, SimpleForm } from "react-admin";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomTextField from "../../../ui/custom-text-field";
import { ContentPageSX } from "../../../styled/custom";
import { email, hasValue, minLength } from "../../../utils/schemas";
import EditTitle from "../../../ui/edit-title";
import CustomEditToolbar from "../../../ui/custom-edit-toolbar";
import { useGetList } from "ra-core";
import CustomSelect from "../../../ui/custom-select";
import GroupFields from "../../../styled/group-fields";

const Schema = Yup.object({
  customer_id: hasValue(),
  name: minLength(2, "Digite o nome"),
  type: hasValue(),
  latitude: hasValue(),
  longitude: hasValue(),
  radius: hasValue(),
});

const CustomerTransmitterEdit = () => {
  const { data: customer, isLoading: isLoadingCustomer } =
    useGetList("customer");

  return (
    <Edit
      title={<EditTitle subtitle="Editar Transmissor: " source="name" />}
      redirect="list"
      mutationMode="pessimistic"
      sx={ContentPageSX}
    >
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomEditToolbar />}
      >
        {!isLoadingCustomer && (
          <CustomSelect
            data={customer}
            name="customer_id"
            label="Afiliado"
            multiple={false}
            fullWidth
            isRequired
          />
        )}

        <CustomTextField name="name" label="Nome" fullWidth isRequired />
        <CustomTextField name="type" label="Tipo" fullWidth isRequired />
        <GroupFields>
          <CustomTextField
            name="latitude"
            label="Latitude"
            fullWidth
            isRequired
            type="number"
          />
          <CustomTextField
            name="longitude"
            label="Longitude"
            fullWidth
            isRequired
            type="number"
          />
        </GroupFields>
        <CustomTextField
          name="radius"
          label="Radio de alcance (metros)"
          fullWidth
          isRequired
          type="number"
        />
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  );
};

export default CustomerTransmitterEdit;
