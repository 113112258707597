import React from "react";
import {
  Edit,
  SimpleForm,
  TabbedForm,
  BooleanInput,
  useGetList,
  ReferenceManyField,
  Datagrid,
  TextField,
  DateField,
  EmailField,
  NumberField,
  List,
  SimpleShowLayout,
  Button,
  useRecordContext,
  useEditContext,
  useNotify,
  FormDataConsumer,
} from "react-admin";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSelect from "../../../ui/custom-select";
import { ContentPageSX, DatagridSX } from "../../../styled/custom";
import CustomTextField from "../../../ui/custom-text-field";
import CustomAutocomplete from "../../../ui/custom-autocomplete";
import EditTitle from "../../../ui/edit-title";
import CustomEditToolbar from "../../../ui/custom-edit-toolbar";
import { hasValue, email, minLength, cnpj } from "../../../utils/schemas";
import GroupFields from "../../../styled/group-fields";

import Geocoder from "../../../ui/geocoder";

const Schema = Yup.object({
  type_plan_id: hasValue("Selecione um tipo de plano"),
  region_id: hasValue("Selecione uma região"),
  email: email("Digite o e-mail"),
  name: minLength(3, "Digite o nome"),
  register_number: cnpj("Digite o CNPJ"),
  city_id: hasValue("Selecione uma cidade"),
});

const CustomerEdit = () => {
  const { data: dataTypePlan, isLoading: isLoadingTypePlan } =
    useGetList("type-plan");
  const { data: dataRegion, isLoading: isLoadingRegion } = useGetList("region");
  const { data: dataCities, isLoading: isLoadingCities } =
    useGetList("setup/cities");
  const { data: dataProgram, isLoading: isLoadingProgram } =
    useGetList("program");

  const getCityNameById = (id) => {
    if (isLoadingCities) return "";
    if (!dataCities) {
      return "";
    }
    const idx = dataCities.findIndex((item) => item.id === id);
    if (idx >= 0) return dataCities[idx].name;
    return "";
  };

  return (
    <Edit
      title={<EditTitle subtitle="Editar Afiliado: " source="email" />}
      redirect="list"
      mutationMode="pessimistic"
      sx={ContentPageSX}
    >
      <TabbedForm
        noValidate
        toolbar={<CustomEditToolbar />}
        resolver={yupResolver(Schema)}
      >
        <TabbedForm.Tab label="Gerais">
          <CustomTextField source="id" label="Id" disabled />
          <GroupFields>
            {!isLoadingTypePlan && (
              <CustomSelect
                data={dataTypePlan}
                name="type_plan_id"
                label="Tipo de Plano"
                multiple={false}
                fullWidth
                isRequired
              />
            )}
            {!isLoadingRegion && (
              <CustomSelect
                data={dataRegion}
                name="region_id"
                label="Região"
                multiple={false}
                fullWidth
                isRequired
              />
            )}
          </GroupFields>
          <CustomTextField
            name="email"
            label="E-mail"
            type="email"
            fullWidth
            isRequired
          />
          <CustomTextField
            name="password"
            label="Senha"
            type="password"
            fullWidth
          />
          <CustomTextField name="name" label="Nome" fullWidth isRequired />
          <CustomTextField
            name="register_number"
            label="CNPJ"
            fullWidth
            isRequired
          />
          {!isLoadingCities && (
            <CustomAutocomplete
              data={dataCities}
              name="city_id"
              label="Cidade"
              multiple={false}
              fullWidth
              isrequired="true"
            />
          )}
          <CustomTextField name="zip_code" label="CEP" fullWidth />
          <CustomTextField name="street" label="Rua" fullWidth />
          <CustomTextField name="number" label="Número" fullWidth />
          <CustomTextField name="complement" label="Complemento" fullWidth />
          <CustomTextField name="neighborhood" label="Bairro" fullWidth />
          {!isLoadingProgram && (
            <CustomSelect
              data={dataProgram}
              name="programs_id"
              label="Programa(s) Retransmitido(s)"
              fullWidth
            />
          )}

          <GroupFields>
            <CustomTextField name="latitude" label="Latitude" fullWidth type="number" />
            <CustomTextField name="longitude" label="Longitude" fullWidth type="number"/>
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <Geocoder
                  street={formData.street}
                  number={formData.number}
                  neighborhood={formData.neighborhood}
                  city={getCityNameById(formData.city_id)}
                  {...rest}
                />
              )}
            </FormDataConsumer>
          </GroupFields>

          <BooleanInput source="active" label="Ativo" />
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
};

export default CustomerEdit;
