import React from "react";
import { Edit, SimpleForm } from "react-admin";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomTextField from "../../../ui/custom-text-field";
import { ContentPageSX } from "../../../styled/custom";
import { email, hasValue, minLength } from "../../../utils/schemas";
import EditTitle from "../../../ui/edit-title";
import CustomEditToolbar from "../../../ui/custom-edit-toolbar";
import { useGetList } from "ra-core";
import CustomSelect from "../../../ui/custom-select";

const Schema = Yup.object({
  customer_id: hasValue(),
  name: minLength(3, "Digite o nome"),
  position: minLength(2, "Digite o cargo"),
  email: email("Digite o e-mail"),
});

const CustomerStaffMemberEdit = () => {
  const { data: customer, isLoading: isLoadingCustomer } =
    useGetList("customer");

  return (
    <Edit
      title={<EditTitle subtitle="Editar Membro do Staff: " source="name" />}
      redirect="list"
      mutationMode="pessimistic"
      sx={ContentPageSX}
    >
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomEditToolbar />}
      >
        {!isLoadingCustomer && (
          <CustomSelect
            data={customer}
            name="customer_id"
            label="Afiliado"
            multiple={false}
            fullWidth
            isRequired
          />
        )}

        <CustomTextField name="name" label="Nome" fullWidth isRequired />
        <CustomTextField name="position" label="Cargo" fullWidth isRequired />
        <CustomTextField name="email" label="Email" fullWidth isRequired />
        <CustomTextField name="phone" label="Telefone" fullWidth isRequired />
      </SimpleForm>
    </Edit>
  );
};

export default CustomerStaffMemberEdit;
