import React from 'react';
import { Create, SimpleForm, useGetList } from 'react-admin';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContentPageSX } from '../../../styled/custom';
import CustomTextField from '../../../ui/custom-text-field';
import CustomSelect from '../../../ui/custom-select';
import CustomAutocomplete from '../../../ui/custom-autocomplete';
import { email, hasValue, minLength, cnpj } from '../../../utils/schemas';
import GroupFields from '../../../styled/group-fields';

const Schema = Yup.object(
{
  type_plan_id: hasValue("Selecione um tipo de plano"),
  region_id: hasValue("Selecione uma região"),
  email: email("Digite o e-mail"),
  password: minLength(6, "Digite uma senha"),
  name: minLength(3, "Digite o nome"),
  register_number: cnpj("Digite o CNPJ"),
  city_id: hasValue("Selecione uma cidade")
});

const CustomerCreate = () =>
{
  const { data: dataTypePlan, isLoading: isLoadingTypePlan } = useGetList("type-plan");
  const { data: dataRegion, isLoading: isLoadingRegion } = useGetList("region");
  const { data: dataCities, isLoading: isLoadingCities } = useGetList("setup/cities");
  const { data: dataProgram, isLoading: isLoadingProgram } = useGetList("program");

  return (
    <Create
      title="Novo Afiliado"
      redirect="list"
      sx={ContentPageSX}>
      <SimpleForm noValidate resolver={yupResolver(Schema)}>
        <GroupFields>
          {!isLoadingTypePlan && <CustomSelect data={dataTypePlan} name="type_plan_id" label="Tipo de Plano" multiple={false} fullWidth isRequired />}
          {!isLoadingRegion && <CustomSelect data={dataRegion} name="region_id" label="Região" multiple={false} fullWidth isRequired />}
        </GroupFields>
        <CustomTextField name="email" label="E-mail" type="email" fullWidth isRequired  />
        <CustomTextField name="password" label="Senha" type="password" fullWidth isRequired  />
        <CustomTextField name="name" label="Nome" fullWidth isRequired />
        <CustomTextField name="register_number" label="CNPJ" fullWidth isRequired />
        {!isLoadingCities && <CustomAutocomplete data={dataCities} name="city_id" label="Cidade" multiple={false} fullWidth isrequired="true"/>}
        <CustomTextField name="zip_code" label="CEP" fullWidth />
        <CustomTextField name="street" label="Rua" fullWidth />
        <CustomTextField name="number" label="Número" fullWidth />
        <CustomTextField name="complement" label="Complemento" fullWidth />
        <CustomTextField name="neighborhood" label="Bairro" fullWidth />
        {!isLoadingProgram && <CustomSelect data={dataProgram} name="programs" label="Programa(s) Retransmitido(s)" fullWidth />}
      </SimpleForm>
    </Create>
  );
}

export default CustomerCreate;
