import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  DeleteWithConfirmButton,
} from "react-admin";
import { DatagridSX, ListSX } from "../../../styled/custom";
import CustomFilterCustomer from "../../../filter/custom-filter-customer";
import CustomListDefaultToolbar from "../../../ui/custom-list-default-toolbar";
import CustomerExporter from "../../../exporter/customer-exporter";

const CustomerStaffMemberList = (props) => (
  <List
    title="Membros do Staff"
    sort={{ field: "id", order: "desc" }}
    filters={CustomFilterCustomer}
    exporter={CustomerExporter}
    actions={<CustomListDefaultToolbar />}
    sx={ListSX}
  >
    <Datagrid rowClick="show" bulkActionButtons={false} sx={DatagridSX}>
      <TextField source="id" sortable={true} />
      <TextField source="name" label="Nome" sortable={true} />
      <TextField source="position" label="Cargo" sortable={true} />
      <TextField source="email" label="E-mail" sortable={false} />
      <TextField source="name" label="Nome" sortable={true} />
      <TextField source="customer.name" label="Afiliada" sortable={true} />
      <DateField
        source="created_at"
        locales="pt-BR"
        label="Criado em"
        sortable={false}
        showTime
      />
      <EditButton />
      <DeleteWithConfirmButton />
    </Datagrid>
  </List>
);

export default CustomerStaffMemberList;
