import React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { DatagridSX, ListSX } from '../../../styled/custom';
import CustomFilterCustomerSpotReceipt from '../../../filter/custom-filter-customer-spot-receipt';
import CustomListCreateToolbar from '../../../ui/custom-list-create-toolbar';
import LinkField from '../../../ui/link-field';
import ControlAudio from '../../../ui/control-audio';

const CustomerSpotReceiptList = () => (
  <List
    title="Comprovantes de Veiculação"
    filters={ CustomFilterCustomerSpotReceipt }
    actions={ <CustomListCreateToolbar /> }
    sx={ListSX}>
    <Datagrid
      bulkActionButtons={false}
      sx={DatagridSX}>
      <TextField source="id" sortable={false} />
      <TextField source="spot_name" label="Spot" sortable={false} />
      <ControlAudio label="Player" />
      <LinkField source="url" label="Url Comprovante" text="Ver" sortable={false} />
      <TextField source="type" label="Tipo" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Data" sortable={false} showTime />
    </Datagrid>
  </List>
)

export default CustomerSpotReceiptList;
