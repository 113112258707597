import { Circle, InfoWindow } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { Link, useCreatePath, useGetList } from "react-admin";
import Typography from "@mui/material/Typography";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const TransmitterMarker = ({ transmitter }) => {
  const createPath = useCreatePath();
  const [isOpen, setIsOpen] = useState(false);

  if (transmitter.latitude && transmitter.longitude) {
    return (
      <>
        <Circle
          title={transmitter.name}
          center={{ lat: transmitter.latitude, lng: transmitter.longitude }}
          radius={transmitter.radius}
          onClick={() => {
            setIsOpen(true);
            console.log("Clicou", transmitter.id);
          }}
          options={{
            strokeWeight: 1,
          }}
          zIndex={1}
        />
        {isOpen && (
          <InfoWindow
            position={{ lat: transmitter.latitude, lng: transmitter.longitude }}
            onCloseClick={() => setIsOpen(false)}
          >
            <div>
              <Typography>Nome:{transmitter.name}</Typography>
              <Typography>Tipo:{transmitter.type}</Typography>
              <Typography>Alcance: {transmitter.radius} m</Typography>
              <Link
                to={createPath({
                  resource: "customer_transmitter",
                  type: "edit",
                  id: transmitter.id,
                })}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ display: "flex" }}
              >
                <OpenInNewIcon />
              </Link>
            </div>
          </InfoWindow>
        )}
      </>
    );
  }
  return null;
};

const CoverageLayer = () => {
  const { data, isLoading } = useGetList("customer_transmitter", {
    pagination: { page: 1, perPage: 1000000 },
    sort: { field: "id", order: "ASC" },
  });

  const [transmitters, setTransmitters] = useState([]);

  useEffect(() => {
    if (data) {
      setTransmitters(data);
    }
  }, [data]);

  if (isLoading) return null;

  return transmitters
    .filter((c) => c.latitude && c.longitude)
    .map((item) => <TransmitterMarker key={item.id} transmitter={item} />);
};

export default CoverageLayer;
