import React from "react";
import { BooleanInput, Create, SimpleForm } from "react-admin";
import { parse } from "query-string";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomTextField from "../../../ui/custom-text-field";
import { ContentPageSX } from "../../../styled/custom";
import { hasValue, minLength } from "../../../utils/schemas";
import { useGetList } from "ra-core";
import CustomSelect from "../../../ui/custom-select";
import { useLocation } from "react-router";
import GroupFields from "../../../styled/group-fields";

const Schema = Yup.object({
  customer_id: hasValue(),
  name: minLength(2, "Digite o nome"),
  type: hasValue(),
  latitude: hasValue(),
  longitude: hasValue(),
  radius: hasValue(),
});

const CustomerTransmitterCreate = (props) => {
  const location = useLocation();
  const { customer_id } = parse(location.search);

  const redirect = customer_id
    ? `/customer/${customer_id}/show/transmitters`
    : "show";

  const { data: customer, isLoading: isLoadingCustomer } =
    useGetList("customer");


  return (
    <Create title="Novo transmissor" sx={ContentPageSX} redirect={redirect}>
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        defaultValues={{ customer_id }}
      >
        {!isLoadingCustomer && (
          <CustomSelect
            data={customer}
            name="customer_id"
            label="Afiliado"
            multiple={false}
            fullWidth
            isRequired
          />
        )}
        <CustomTextField name="name" label="Nome" fullWidth isRequired />
        <CustomTextField name="type" label="Tipo" fullWidth isRequired />
        <GroupFields>
          <CustomTextField
            name="latitude"
            label="Latitude"
            fullWidth
            isRequired
            type="number"
          />
          <CustomTextField
            name="longitude"
            label="Longitude"
            fullWidth
            isRequired
            type="number"
          />
        </GroupFields>
        <CustomTextField
          name="radius"
          label="Radio de alcance (metros)"
          fullWidth
          isRequired
          type="number"
        />
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Create>
  );
};

export default CustomerTransmitterCreate;
