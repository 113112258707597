import React from "react";
import { useSelector } from "react-redux";
import PopulationHeatmapLayer from "./layers/population-heatmap-layer";
import CoverageLayer from "./layers/coverage-layer";
import CustomersLayer from "./layers/customers-layer";

const GeoLayers = () => {
  const { showPopulationLayer, showAffiliatesLayer, showCoverageLayer } =
    useSelector((state) => ({
      showPopulationLayer: state.showPopulationLayer,
      showAffiliatesLayer: state.showAffiliatesLayer,
      showCoverageLayer: state.showCoverageLayer,
    }));

  return [
    showPopulationLayer ? <PopulationHeatmapLayer key="cities-heatmap" /> : null,
    showAffiliatesLayer ? <CustomersLayer key="customers" /> : null,
    showCoverageLayer ? <CoverageLayer key="coverage" /> : null,
  ];
};

export default GeoLayers;
