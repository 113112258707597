import { Marker } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useGetList } from "react-admin";
import { useDispatch } from "react-redux";
import { setCustomerId, toggleCustomerProfile } from "../store";

const CustomerLayer = () => {
  const { data, isLoading } = useGetList("customer", {
    pagination: { page: 1, perPage: 1000000 },
    sort: { field: "id", order: "ASC" },
  });

  const [customers, setCustomers] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      console.log(data);
      setCustomers(data);
    }
  }, [data]);

  if (isLoading) return null;

  return customers
    .filter((c) => c.latitude && c.longitude)
    .map((item) => (
      <Marker
        key={item.id}
        title={item.name}
        position={{ lat: item.latitude, lng: item.longitude }}
        zIndex={1}
        onClick={() => {
            dispatch(setCustomerId(item.id));
            dispatch(toggleCustomerProfile(true));
        }}
      />
    ));
};

export default CustomerLayer;
