import { configureStore, createSlice } from "@reduxjs/toolkit";

const initialState = {
  map: null,
  openDrawer: false,
  drawerType: "",
  isAdmin: false,

  showIntroduction: false,
  showAffiliationRequest: false,
  showItasatNetwork: false,

  showPopulationLayer: false,
  showCoverageLayer: false,
  showAffiliatesLayer: true,

  publicRadios: [],
  selectedRadio: null,
  publicRadioFilter: "",

  showQueryTool: false,
  queryShape: "none",
  queryColor: "#ba00ff",
  regionQueryList: [],

  showCustomerProfile: false,
  customerId: null,
};

export const appSlice = createSlice({
  name: "geo",
  initialState,
  reducers: {
    setMap(state, action) {
      state.map = action.payload;
    },
    setIsAdmin(state, action) {
      state.isAdmin = action.payload;
    },
    setShowIntroduction(state, action) {
      state.showIntroduction = action.payload;
    },
    setShowAffiliateRequest(state, action) {
      state.showAffiliationRequest = action.payload;
    },
    setDrawerType(state, action) {
      state.drawerType = action.payload;
    },
    toggleItasatNetwork(state, action) {
      if (action.payload == null) {
        state.showItasatNetwork = !state.showItasatNetwork;
      } else {
        state.showItasatNetwork = action.payload;
      }
    },
    toggleQueryTool(state, action) {
      if (action.payload == null) {
        state.showQueryTool = !state.showQueryTool;
      } else {
        state.showQueryTool = action.payload;
      }
    },
    setShowPopulationLayer(state, action) {
      state.showPopulationLayer = action.payload;
    },
    setShowCoverageLayer(state, action) {
      state.showCoverageLayer = action.payload;
    },
    setShowAffiliatesLayer(state, action) {
      state.showAffiliatesLayer = action.payload;
    },

    setPublicRadios(state, action) {
      state.publicRadios = action.payload;
    },
    setSelectedRadio(state, action) {
      state.selectedRadio = action.payload;
    },
    setPublicRadioFilter(state, action) {
      state.publicRadioFilter = action.payload;
    },
    addRegionQuery(state, action) {
      state.regionQueryList.push(action.payload);
    },
    clearRegionQueries(state, action) {
      state.regionQueryList.forEach((e) => e.feature.setMap(null));
      state.regionQueryList = [];
    },
    setQueryShape(state, action) {
      if (action.payload === "circle" || action.payload === "polygon")
        state.queryShape = action.payload;
      else state.queryShape = "none";
    },
    setQueryColor(state, action) {
      state.queryColor = action.payload;
    },
    toggleRegionVisibility(state, action) {
      const { index, value } = action.payload;
      if (index < state.regionQueryList.length) {
        if (value == null) {
          if (state.regionQueryList[index].visible) {
            state.regionQueryList[index].feature.setMap(null);
            state.regionQueryList[index].visible = false;
          } else {
            state.regionQueryList[index].feature.setMap(state.map);
            state.regionQueryList[index].visible = true;
          }
        } else {
          if (value) {
            state.regionQueryList[index].feature.setMap(state.map);
            state.regionQueryList[index].visible = true;
          } else {
            state.regionQueryList[index].feature.setMap(null);
            state.regionQueryList[index].visible = false;
          }
        }
      }
    },
    removeRegion(state, action) {
      const index = action.payload;
      if (index < state.regionQueryList.length && index >= 0) {
        let newList = [...state.regionQueryList];
        state.regionQueryList[index].feature.setMap(null);
        newList.splice(index, 1);
        state.regionQueryList = newList;
      }
    },

    toggleCustomerProfile(state, action) {
      if (action.payload == null) {
        state.showCustomerProfile = !state.showCustomerProfile;
      } else {
        state.showCustomerProfile = action.payload;
      }
    },

    setCustomerId(state, action) {
      state.customerId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setMap,
  setIsAdmin,
  setShowIntroduction,
  setShowAffiliateRequest,
  setDrawerType,
  toggleItasatNetwork,
  setShowPopulationLayer,
  setShowCoverageLayer,
  setShowAffiliatesLayer,
  setPublicRadios,
  setSelectedRadio,
  setPublicRadioFilter,

  addRegionQuery,
  clearRegionQueries,
  toggleQueryTool,
  setQueryShape,
  setQueryColor,
  toggleRegionVisibility,
  removeRegion,

  toggleCustomerProfile,
  setCustomerId,
} = appSlice.actions;

export const createStore = (initialState) => {
  return configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }),
    reducer: appSlice.reducer,
  });
};

let appStore = createStore();


export const getAppStore = () => appStore;
export const resetAppStore = () => { 
  appStore = createStore();
}
