import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br'

const ProgrammingMapListTitle = () =>
{
  var currentMonthAndYear  = moment().locale('pt-br').format('MMMM yyyy');
  currentMonthAndYear = currentMonthAndYear.charAt(0).toUpperCase() + currentMonthAndYear.slice(1);
  return <span>Mídia ItaSat - Comerciais - { currentMonthAndYear }</span>
}

export default ProgrammingMapListTitle;
