import React from 'react';
import { Create, SimpleForm, FileInput, FileField, useGetList } from 'react-admin';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomSelect from '../../../ui/custom-select';
import { ContentPageSX } from '../../../styled/custom';
import { hasValue, filesSize } from '../../../utils/schemas';

const Schema = Yup.object(
{
  category_media_id: hasValue("Selecione uma categoria"),
  files: filesSize(200000000, "Selecione 1 ou mais arquivos com tamanho máximo de 200 MB no total")
});

const MediaCreate = () =>
{
  const { data, isLoading } = useGetList("category-media");
  return (
    <Create
      title="Novo Produto da Cesta"
      redirect="list"
      sx={ContentPageSX}>
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}>
        { !isLoading && <CustomSelect data={data} name="category_media_id" label="Categoria" multiple={false} fullWidth isRequired /> }
        <FileInput source="files" label="Tamanho Máximo dos Arquivos Somados: 200 MB" multiple={true} maxSize={200000000} accept="application/*, video/*, audio/*, image/*">
          <FileField src="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
}

export default MediaCreate;
